import {
    createApp
} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import LayoutBase from "@/components/layouts/LayoutBase.vue";
import LoadingProgress from "@/components/UI/LoadingProgress";
import MobileSpiner from "@/components/UI/MobileSpiner";

const app = createApp(App);

app.component("LayoutBase", LayoutBase);

import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Sidebar from "primevue/sidebar";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import StyleClass from "primevue/styleclass";
import Ripple from "primevue/ripple";

import MultiSelect from "primevue/multiselect";
import Card from "primevue/card";
import Tag from "primevue/tag";
import InputSwitch from "primevue/inputswitch";
import ProgressSpinner from "primevue/progressspinner";
import ProgressBar from "primevue/progressbar";
import Tooltip from "primevue/tooltip";
import Chip from "primevue/chip";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import InputMask from "primevue/inputmask";
import Password from "primevue/password";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; //optional for column grouping
import Row from "primevue/row";
import TreeSelect from "primevue/treeselect";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Checkbox from "primevue/checkbox";
import Calendar from "primevue/calendar";
import OverlayPanel from "primevue/overlaypanel";
import Listbox from "primevue/listbox";
import ScrollTop from "primevue/scrolltop";
import RadioButton from "primevue/radiobutton";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import ToggleButton from "primevue/togglebutton";
import Paginator from "primevue/paginator";
import Message from "primevue/message";
import Chart from "primevue/chart";
import SelectButton from "primevue/selectbutton";
import Menu from "primevue/menu";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import SplitButton from "primevue/splitbutton";
import TieredMenu from "primevue/tieredmenu";
import Slider from "primevue/slider";
import VirtualScroller from "primevue/virtualscroller";
import Skeleton from "primevue/skeleton";
import Inplace from 'primevue/inplace';
import ThePaginator from '@/components/UI/ThePaginator'
import PartnerReferal from "@/components/PartnerReferal";


app.use(PrimeVue, {
    ripple: true
});
app.use(ConfirmationService);
app.component("Dialog", Dialog);
app.component("Button", Button);
app.component("InputText", InputText);
app.use(ToastService);
app.component("Toast", Toast);
app.component("Sidebar", Sidebar);
app.directive("badge", BadgeDirective);
app.component("Badge", Badge);
app.directive("styleclass", StyleClass);
app.directive("ripple", Ripple);


app.component("MultiSelect", MultiSelect);
app.component("Card", Card);
app.component("Tag", Tag);
app.component("InputSwitch", InputSwitch);
app.component("ProgressSpinner", ProgressSpinner);
app.component("ProgressBar", ProgressBar);
app.component("Chip", Chip);
app.component("InputNumber", InputNumber);
app.component("Dropdown", Dropdown);
app.component("LoadingProgress", LoadingProgress);
app.directive("tooltip", Tooltip);
app.component("InputMask", InputMask);
app.component("Password", Password);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
app.component("TreeSelect", TreeSelect);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("Checkbox", Checkbox);
app.component("Calendar", Calendar);
app.component("OverlayPanel", OverlayPanel);
app.component("Listbox", Listbox);
app.component("ScrollTop", ScrollTop);
app.component("RadioButton", RadioButton);
app.component("ConfirmPopup", ConfirmPopup);
app.component("ToggleButton", ToggleButton);
app.component("Paginator", Paginator);
app.component("Message", Message);
app.component("Chart", Chart);
app.component("SelectButton", SelectButton);
app.component("Menu", Menu);
app.component("Avatar", Avatar);
app.component("AvatarGroup", AvatarGroup);
app.component("MobileSpiner", MobileSpiner);
app.component("SplitButton", SplitButton);
app.component("TieredMenu", TieredMenu);
app.component("Slider", Slider);
app.component("VirtualScroller", VirtualScroller);
app.component("Skeleton", Skeleton);
app.component("Inplace", Inplace);
app.component("ThePaginator", ThePaginator);

app.component("PartnerReferal", PartnerReferal);



import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./assets/theme/theme.scss";
import './assets/fonts/delta-font-stable/style.css'

app.use(store).use(router);

app.mount("#app");